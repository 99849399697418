Wallet.Country = function() {
    this.countries = [];
    this.init();
};

Wallet.Country.prototype = {
    init: function() {
        var self = this;
        var configurationUrl = $('#configurationUrl').val();
        if (Wallet.Country.Store.length === 0) {
            $.ajax({
                url: configurationUrl,
                type: 'get',
                fail: function () {
                },
                success: function (response) {
                    self.countries = response;
                    Wallet.Country.Store = response;
                },
                async: false
            });
        } else {
            self.countries = Wallet.Country.Store;
        }
    },
    get: function (countryCode) {
        var country = null;
        _.each(this.countries, function (countrySpec) {
            if(countrySpec.id == countryCode){
                country = countrySpec;
            }
        });
        return country;
    }
};

Wallet.Country.Store = [];
