window.Wallet = window.Wallet || {};
window.Wallet.Wizard = {};

$.validator.setDefaults({
    onkeyup: false,
    onfocusout: false,
    errorElement: "p",
    errorClass: "form-error"
});

$.fn.isHidden = function (value) {
    $(this).toggleClass('is-hidden', value);
};

$.fn.moveTo = function (scrollPosition, scrollSpeed) {
    var speed = scrollSpeed || 500;
    $(this).animate({
        scrollTop: scrollPosition
    }, speed);
};

jQuery(function () {
    $(".js-disablePaste").bind('paste', function (e) {
        e.preventDefault();
    });

    $(".js-submitOnce").bind('submit', function (event) {
        var form = $(this);
        if (form.valid()) {
            if (form.hasClass('form-submitted')) {
                event.preventDefault();
                return;
            }
            form.addClass('form-submitted');
        }
    });

    $('.js-submitInsideForm').on('click', function () {
        var form = $(this).closest('form');
        if (form.valid()) {
            form.submit();
        }
    });

    $("#deposit_new_card_card_holder_name").bind('focusout', function (e) {
        if (e.which >= 97 && e.which <= 122) {
            var newKey = e.which - 32;
            e.keyCode = newKey;
            e.charCode = newKey;
        }

        $("#deposit_new_card_card_holder_name").val(($("#deposit_new_card_card_holder_name").val()).trim().toUpperCase());
    });

    $('.js-fieldPhoneCode').on('click', function () {
        $(this).next().focus();
    });

    // Please keep the order of below calls otherwise in IE8 placeholder will not working
    $("form.js-resetOnRefresh").each(function () {
        this.reset();
    });

    //BOOTSTRAP MODULES
    $('[data-toggle="tooltip"]').tooltip()
});
