Wallet.CountryConfiguration = function (options) {
    this.options = jQuery.extend({}, this.defaults, options);
    this.countries = new Wallet.Country();
    this.attributes = options.attributes;
};

Wallet.CountryConfiguration.prototype = {

    process: function () {
        var self = this;
        var attributes = self.attributes;

        self._populateFields(attributes.country.val());

        attributes.country.on('change', function () {
            self._populateFields($(this).val());
        });
    },
    _populateFields: function (countryName) {

        function getCountryName(countryName) {
            return function (index, value) {
                return countryName ? value : '';
            };
        }

        var disabled = !this.attributes.country.find('option').filter(':selected').val();

        for (var k in this.attributes.dob) {
            this.attributes.dob[k].prop('disabled', disabled).val(getCountryName(countryName));
        }

        this._populateYearOfBirth(countryName);
    },
    _populateYearOfBirth: function (countryName) {

        if (!this.attributes.dob || !countryName || !this.countries.countries.length) {
            return;
        }

        var currentYear = new Date().getFullYear(),
            dobFullDate = this.attributes.dob.full.val().split('-'),
            countryAttributes = this.countries.get(countryName);

        this.attributes.dob.year.find('option').remove().end()
            .append('<option value>' + Wallet.I18n.values.selectYear + '</option>');

        for (var year = (currentYear - countryAttributes.age_of_majority.value); year >= (currentYear - 100); year--) {
            this.attributes.dob.year.append('<option value=' + year + '>' + year + '</option>');
        }

        countryAttributes.id && this.attributes.dob.year.val(dobFullDate[0]);
    },
    defaults: {
        attributes: {}
    }
};
