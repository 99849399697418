Wallet.Wizard.Step = function (type) {
    this.callbacks = {
        init: [],
        show: [],
        hide: [],
        back: [],
        process: [],
        success: [],
        error: [],
        refresh: [],
        inProgress: [],
        completed: []
    };
    this.type = type || Wallet.Wizard.StepType.DEFAULT;
};

Wallet.Wizard.StepType = {
    DEFAULT: "default",
    OVERLAY: "overlay",
    SLIDE: "slide"
};

Wallet.Wizard.Step.NULL = {
    init: $.noop,
    hide: $.noop,
    show: $.noop,
    back: $.noop,
    inProgress: $.noop,
    completed: $.noop,
    registerCallbacks: $.noop
};

Wallet.Wizard.Step.prototype = {
    init: function () {
        this._invokeCallbacks('init');
    },
    hide: function () {
        this._invokeCallbacks('hide');
    },
    show: function (payload) {
        this._invokeCallbacks('show', [payload]);
        this._moveToTop();
    },
    back: function () {
        this._invokeCallbacks('back');
    },
    process: function () {
        this._invokeCallbacks('process');
    },
    success: function (payload) {
        this._invokeCallbacks('success', [payload]);
    },
    error: function (payload) {
        this._invokeCallbacks('error', [payload]);
    },
    refresh: function(){
        this._invokeCallbacks('refresh');
    },
    inProgress: function(){
        this._invokeCallbacks('inProgress');
    },
    completed: function(){
        this._invokeCallbacks('completed');
    },
    registerCallbacks: function (callbacks) {
        for(var action in this.callbacks) {
            this._registerCallback(action, callbacks);
        }
    },
    _moveToTop: function () {
        $('html, body').moveTo(0,1000);
    },
    _registerCallback: function(action, callbacks) {
        if (callbacks[action]) {
            this.callbacks[action].push(callbacks[action]);
        }
    },
    _invokeCallbacks: function(action, callbackArgs) {
        var self = this;
        _.each(this.callbacks[action], function(callback) {
            callback.apply(self, callbackArgs || []);
        });
    }
};
