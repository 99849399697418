jQuery(function () {

    var passwordResetForm = jQuery(".js-passwordReset");
    Wallet.Utilities.guardWith(passwordResetForm, function () {
        if (!passwordResetForm.length) {
            return;
        }

        passwordResetForm.validate({
            onfocusout: function (element) {
                $(element).valid();
            }
        });
    });
});